import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Sketch from "react-p5";

class TvSignal extends React.Component {
  state = {
    redirect: false,
  };
  setup = (p5, parent) => {
    p5.createCanvas(window.innerWidth, window.innerHeight);
    p5.pixelDensity(1);
  };

  draw = (p5) => {
    p5.loadPixels();
    let xoff = 0;
    for (let x = 0; x < p5.width; x++) {
      for (let y = 0; y < p5.height; y++) {
        let index = (x + y * p5.width) * 4;
        let r = p5.random(255);
        p5.pixels[index + 0] = r;
        p5.pixels[index + 1] = r;
        p5.pixels[index + 2] = r;
        p5.pixels[index + 3] = 255;
      }
    }
    p5.updatePixels();
    //noLoop();
  };

  render() {
    return this.state.redirect ? (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    ) : (
      <Sketch setup={this.setup} draw={this.draw} />
    );
  }

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), 2500);
  }

  componentWillUnmount() {
    clearTimeout(this.id);
  }
}

ReactDOM.render(<TvSignal />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
