import React from "react";
import logo from "./logo.svg";
import "./App.css";
import TypeIt from "typeit-react";
import { AnimateOnChange } from "react-animation";
import Particles from "react-particles-js";
import $ from "jquery";
import { SocialIcon } from "react-social-icons";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <Particles
          params={{
            particles: {
              number: {
                value: 60,
                density: {
                  enable: true,
                  value_area: 1500,
                },
              },
              line_linked: {
                enable: true,
                opacity: 0.02,
              },
              move: {
                direction: "right",
                speed: 0.05,
              },
              size: {
                value: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.05,
                },
              },
            },
            interactivity: {
              events: {
                onclick: {
                  enable: true,
                  mode: "push",
                },
              },
              modes: {
                push: {
                  particles_nb: 1,
                },
              },
            },
            retina_detect: true,
          }}
        />
        <div className="textcotainer">
          <TypeIt
            className="amed-text"
            options={{
              afterComplete: async (step, instance) => {
                $(".amed-socials").show();
                $(".amed-social.1").delay(300).fadeIn();
                $(".amed-social.2").delay(1300).fadeIn();
                $(".amed-social.3").delay(2300).fadeIn();
              },
            }}
            getBeforeInit={(instance) => {
              instance
                .pause(800)
                .type("hi")
                .pause(40)
                .type(", I'm")
                .pause(850)
                .move(-6, { speed: 35, delay: 250 })
                .delete(1)
                .type("H")
                .move("END", { speed: 100, delay: 250 })
                .type(" a web dev")
                .pause("350")
                .delete(8)
                .pause("100")
                .type("med")
                .pause("200")
                .move(-3, { speed: 80, delay: 250 })
                .delete(1)
                .type("A")
                .move("END", { speed: 100, delay: 250 })
                .pause("250")
                .type("!")
                .pause("750")
                .type("<br> I am a full stack web developer")
                .pause("500")
                .delete(29)
                .pause("500")
                .type("create fun websites ")
                .pause("250")
                .type("💻<br>", { speed: 150, delay: 250 })
                .pause("500")
                .type("I am an entrepreneur")
                .pause("500")
                .delete(19)
                .pause("350")
                .type(" build cool businesses ")
                .pause("250")
                .type("🚀<br>", { speed: 150, delay: 250 })
                .pause("1000")
                .type("Let's talk :)");

              // Remember to return it!
              return instance;
            }}
          />
        </div>
        <div className="amed-socials" style={{ display: "none" }}>
          <div className="amed-social 1">
            <SocialIcon
              network="twitter"
              url="http://twitter.com/thisisamed"
            />
          </div>

          <div className="amed-social 2">
            <SocialIcon
              network="instagram"
              url="http://instagram.com/thisisamed"
            />
          </div>
          <div className="amed-social 3">
            <SocialIcon network="mailto" url="mailto:contact@amed.io" />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
